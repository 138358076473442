import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/layouts/base.js";
import NavigationList from "@components/navigation-list";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <div className="container animate-on-enter-1">
  <NavigationList home={true} variation="sitemap" mdxType="NavigationList" />
    </div>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      